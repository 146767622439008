// ////////////////////////////スマホ/タブレット判別
var _ua = (function (u) {
  return {
    Tablet:
      (u.indexOf('windows') != -1 && u.indexOf('touch') != -1) ||
      u.indexOf('ipad') != -1 ||
      (u.indexOf('android') != -1 && u.indexOf('mobile') == -1) ||
      (u.indexOf('firefox') != -1 && u.indexOf('tablet') != -1) ||
      u.indexOf('kindle') != -1 ||
      u.indexOf('silk') != -1 ||
      u.indexOf('playbook') != -1,
    Mobile:
      (u.indexOf('windows') != -1 && u.indexOf('phone') != -1) ||
      u.indexOf('iphone') != -1 ||
      u.indexOf('ipod') != -1 ||
      (u.indexOf('android') != -1 && u.indexOf('mobile') != -1) ||
      (u.indexOf('firefox') != -1 && u.indexOf('mobile') != -1) ||
      u.indexOf('blackberry') != -1,
  };
})(window.navigator.userAgent.toLowerCase());
// if(_ua.Mobile){}

var osVer;
osVer = 'Android';

var ua = navigator.userAgent.toLowerCase();
var iPadFlag = false;
if (/android|ipod|ipad|iphone|macintosh/.test(ua) && 'ontouchend' in document) {
  iPadFlag = true;
}
// if (navigator.userAgent.indexOf(osVer)>0){
// }

var breakNum = 768;
var tabletNum = 1024;
// ////////////////////////////////////init
$(function () {
  setScroll();
  if ($('#wrapper').hasClass('home')) {
  }
  // ///////////
  if (!_ua.Mobile && !_ua.Tablet) {
    $('#wrapper').addClass('desktop');
    if (!$('#wrapper').hasClass('about')) {
      $('html, body').prop({
        scrollTop: 0,
      });
      if (ua.indexOf('safari') !== -1 && ua.indexOf('chrome') === -1 && ua.indexOf('edge') === -1) {
        // ここにSafari用の記述
      } else {
      }
    }
  }
  // ///////////
});

$(window).on('load', function () {
  // heightLineGroup()
  judgeWinSize();
  // setHeader();
  // setGnav();
  // setAcc();
  setLoaded();
  // setGsap();
  // setSpan();
  // setMainMenu();
  setScroll();
  // setHeader()
  if (!_ua.Mobile) {
  }
  if (!_ua.Mobile && !_ua.Tablet) {
  }
  // if (
  //   $('#wrapper').hasClass('recruit') ||
  //   $('#wrapper').hasClass('service') ||
  //   $('#wrapper').hasClass('works')
  // ) {
  //   setTimeout(function () {
  //     setSlider();
  //   }, 100);
  // } else if ($('#wrapper').hasClass('entry')) {
  //   setBirth();
  //   setForm();
  // } else if ($('#wrapper').hasClass('contact')) {
  //   setForm();
  // } else if ($('#wrapper').hasClass('home') || $('#wrapper').hasClass('works')) {
  //   startScroll();
  // }
  // if ($('#wrapper').hasClass('taxonomy-category-works')) {
  //   worksUnq();
  // }
  // if ($('#wrapper').hasClass('fanclub')) {
  //   setScrollHint();
  // }
});

// ////////////////////////////アコーディオン
function setAcc() {
  $('.accInfo .ttl').on('click', function () {
    $(this).toggleClass('active');
    $(this).next().stop().slideToggle('fast');
  });
}

// //////////////////////////////ロード完了
function setLoaded() {
  $('#wrapper').addClass('loaded');
  loadStart();
  // if ($('#wrapper').hasClass('home')) {
  //   loadStart()
  // }else {
  //   $('#wrapper').addClass('loadedDone')
  //   $('#wrapper').addClass('loadEnd')
  // }
}

function loadStart() {
  timer = setTimeout(function () {
    $('#wrapper').addClass('loadedDone');
  }, 200);
  timer = setTimeout(function () {
    $('#wrapper').addClass('loadEnd');
  }, 1500);
  // $('body,html').animate({
  //   scrollTop: 0
  // }, 0, 'swing')
}

// function setGnav () {
//   var i = 0
//   setInterval(function () {
//     $('#headNav li').eq(i).addClass('start')
//     i++
//     if (i >= $('#headNav li').length) i = 0
//   }, 100)
// }

// ////////////ウィンドウサイズを判別
function judgeWinSize() {
  var winW = $(window).width();
  if (winW > breakNum) {
    $('#wrapper').addClass('setPc');
  } else {
    $('#wrapper').addClass('setSp');
  }

  var timer = false;
  var currentWidth = window.innerWidth;
  window.addEventListener('resize', function () {
    if (currentWidth == window.innerWidth) {
      return;
    }
    currentWidth = window.innerWidth;
    if (timer !== false) {
      clearTimeout(timer);
    }
    timer = setTimeout(function () {
      winW = $(window).width();
      if (winW > breakNum) {
        $('#wrapper').addClass('setPc');
        $('#wrapper').removeClass('setSp');
      } else {
        $('#wrapper').addClass('setSp');
        $('#wrapper').removeClass('setPc');
      }
    }, 200);
  });
}

// /////////////////////////////スムーススクロール
function setScroll() {
  // var headerHight = $('header').height() + 40
  // var headerHight = $('header').height()
  $('a[href^="#"]').click(function (e) {
    var href = $(this).attr('href');
    var target = $(href == '#' || href == '' ? 'html' : href);
    var position = target.offset().top;

    if ($(this).hasClass('unqNav')) {
      closeFnc();
    }
    $.when(
      $('html, body').animate(
        {
          scrollTop: position,
        },
        400,
        'swing'
      ),
      e.preventDefault()
    ).done(function () {
      var diff = target.offset().top;
      if (diff === position) {
      } else {
        $('html, body').animate(
          {
            scrollTop: diff,
          },
          400,
          'swing'
        );
      }
    });
  });
}

function startScroll() {
  // var headerHight = 20
  if ($('#wrapper').hasClass('setSp')) {
    var headerHight = $('header').height();
  } else {
    var headerHight = 0;
  }
  var href = $(location).attr('hash');
  if (href) {
    timer = setTimeout(function () {
      var target = $(href == '#' || href == '' ? 'html' : href);
      var position = target.offset().top - headerHight;
      $.when(
        $('html, body').animate(
          {
            scrollTop: position,
          },
          400,
          'swing'
        )
      ).done(function () {
        var diff = target.offset().top - headerHight;
        if (diff === position) {
        } else {
          $('html, body').animate(
            {
              scrollTop: diff,
            },
            10,
            'swing'
          );
        }
      });
    }, 500);
  }
}

// ////////////////////////////////////ランドスケープ判定
var isLandscape = function () {
  if (window.innerHeight > window.innerWidth) {
    jQuery('body').addClass('portrait');
    jQuery('body').removeClass('landscape');
  } else {
    jQuery('body').addClass('landscape');
    jQuery('body').removeClass('portrait');
  }
};

if (_ua.Mobile) {
  jQuery(window).resize(function () {
    isLandscape();
  });
  isLandscape();
}

// //////////////////////////////高さ揃え
function heightLineGroup() {
  // setAutoHeight('#photoDiary .articleStyle_02 .inner')
  var winW = $(window).width();
  if (winW > breakNum) {
    // setAutoHeight('.setH_01 h2', 4)
    // setAutoHeight('.setH_01 h2')
    // setAutoHeight('#photoDiary .articleStyle_02 .inner')
    // setAutoHeight(".setH_01 .summary",3)
  } else {
    setAutoHeight('.setH_01 h2', 2);
    setAutoHeight('.setH_02 h2', 2);
    // setAutoHeight("#setH_08 h2",2)
  }

  startResize();
}

function setAutoHeight(target, count) {
  $(target).tile(count);
}

function startResize() {
  var timer = false;
  var currentWidth = window.innerWidth;
  window.addEventListener('resize', function () {
    if (currentWidth == window.innerWidth) {
      return;
    }
    currentWidth = window.innerWidth;
    if (timer !== false) {
      clearTimeout(timer);
    }
    timer = setTimeout(function () {
      heightLineGroup();
    }, 200);
  });
}
